#title{
    position: absolute;
 
    top: 0;
}
p{
    font-family: 'Abel', sans-serif;
}
.profile-description{
    font-size: 1.5em;
}
.education{
    text-align: left;
    font-size: 1.25em;
}
.education-icon{
    margin-left: 50px;
}
.education-icon img{
    width: 10vw; 
    float: left; 
    margin-right: 25px;
    transition: 0.5s ease;
    cursor: pointer;
    margin-bottom: 25px;
}
.education-icon img:hover{
    filter: drop-shadow(2.5px 2.5px 2.5px rgba(0,0,0,0.5));
    transform: translateY(-5px);
}
.paragraph-header{
    font-size: 2em;
}
.section-header{
    margin-left: 50px;
    font-size: 2em;
}

.fade-in {
    animation: fadeIn ease 2s;
    -webkit-animation: fadeIn ease 2s;
    -moz-animation: fadeIn ease 2s;
    -o-animation: fadeIn ease 2s;
    -ms-animation: fadeIn ease 2s;
    }
    @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }
    
    @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }
    
    @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }
    
    @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }
    
    @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }
.videoBG{
    position: relative;
}
.profile-image{
    position: absolute;
    left: 70vw;
    top: 30.1vw;
    width: 25vw;
}
.logo{
    position: absolute; 
    top: 29vw;
    left: 0;
    width: 44vw;
}

//contact information
.contact-item-title{
    position: absolute;
    font-size: 1.5rem;
    padding: 15px;
    top: 51.5vw;
    left: 79vw;
    width: 15vw;
    transition: 0.5s ease;
    cursor: pointer;
    color: #555;
}
.contact-item1{
    position: absolute;
    font-size: 4rem;
    padding: 15px;
    top: 52vw;
    left: 78vw;
    width: 15vw;
    transition: 0.5s ease;
    cursor: pointer;
    color: #555;
}
.contact-item2{
    position: absolute;
    font-size: 4rem;
    padding: 15px;
    top: 52vw;
    left: 81vw;
    width: 15vw;
    transition: 0.5s ease;
    cursor: pointer;
    color: #555;
}
.contact-item3{
    position: absolute;
    font-size: 4rem;
    padding: 15px;
    top: 52vw;
    left: 84vw;
    width: 15vw;
    transition: 0.5s ease;
    cursor: pointer;
    color: #555;
}
.contact-item1:hover{
    filter: drop-shadow(2.5px 2.5px 2.5px rgba(0,0,0,0.5));
    transform: translateY(-5px);
    color: #555;
}
.contact-item2:hover{
    filter: drop-shadow(2.5px 2.5px 2.5px rgba(0,0,0,0.5));
    transform: translateY(-5px);
    color: #555;
}
.contact-item3:hover{
    filter: drop-shadow(2.5px 2.5px 2.5px rgba(0,0,0,0.5));
    transform: translateY(-5px);
    color: #555;
}
.mainContainer{
    margin-top: 40vw;
}
@media only screen and (max-width: 2100px) {
    .contact-item1{
      font-size: 4rem;
      left: 70vw;
      top: 52vw;
    }
    .contact-item2{
      font-size: 4rem;
      left: 78vw;
      top: 52vw;
      }
    .contact-item3{
      font-size: 4rem;
      left: 85vw;
      top: 52vw;
    }
    .contact-item-title{
      font-size: 1.3rem;
      left: 72vw;
      width: 500px;
      top: 51vw;
    }
    .mainContainer{
        margin-top: 40vw;
    }
}

@media only screen and (max-width: 1600px) {

    .contact-item1{
      font-size: 4rem;
      left: 70vw;
      top: 52vw;
    }
    .contact-item2{
      font-size: 4rem;
      left: 78vw;
      top: 52vw;
      }
    .contact-item3{
      font-size: 4rem;
      left: 85vw;
      top: 52vw;
    }
    .contact-item-title{
      font-size: 1.3rem;
      left: 72vw;
      width: 500px;
      top: 51vw;
    }
.mainContainer{
    margin-top: 600px;
}
}
@media only screen and (max-width: 1200px) {

    .contact-item1{
      font-size: 4rem;
      left: 70vw;
      top: 52vw;
    }
    .contact-item2{
      font-size: 4rem;
      left: 78vw;
      top: 52vw;
      }
  .contact-item3{
      font-size: 4rem;
      left: 85vw;
      top: 52vw;
  }
  .contact-item-title{
    font-size: 1.3rem;
    left: 72vw;
    width: 500px;
    top: 51vw;
}
.mainContainer{
    margin-top: 450px;
}
    
  }
  @media only screen and (max-width: 992px) {

    .contact-item1{
      font-size: 3rem;
      left: 5vw;
      top: 45vw;
    }
    .contact-item2{
      font-size: 3rem;
      left: 35vw;
      top: 45vw;
      }
  .contact-item3{
      font-size: 3rem;
      left: 63vw;
      top: 45vw;
  }
  .contact-item-title{
    font-size: 1.3rem;
    left: 1vw;
    width: 500px;
    top: 43vw;
}
.mainContainer{
    margin-top: 450px;
}
    
  }
@media only screen and (max-width: 768px) {

    .contact-item1{
      font-size: 3rem;
      left: 5vw;
      top: 45vw;
    }
    .contact-item2{
      font-size: 3rem;
      left: 35vw;
      top: 45vw;
      }
  .contact-item3{
      font-size: 3rem;
      left: 63vw;
      top: 45vw;
  }
  .contact-item-title{
    font-size: 1.3rem;
    left: 1vw;
    width: 500px;
    top: 43vw;
}
.mainContainer{
    margin-top: 350px;
}
    
  }
@media only screen and (max-width: 600px) {
    .contact-item1{
        font-size: 3.5rem;
        left: 5vw;
        top: 45vw;
    }
    .contact-item2{
        font-size: 3.5rem;
        left: 35vw;
        top: 45vw;
    }
    .contact-item3{
        font-size: 3.5rem;
        left: 63vw;
        top: 45vw;
    }
    .education-icon{
        text-align: center;
        display: block;
        margin: 0 auto;
    }
    .education-icon img{
        width: 40vw; 
        float: none;
        margin-bottom: 10px;
    }   
    .education{
        text-align: center;
        clear: both;
    }
    .contact-item-title{
        font-size: 1.3rem;
        left: 1vw;
        width: 100%;
        top: 43vw;
   }
    .mainContainer{
        margin-top: 300px;
    }
    .profile-description{
        font-size: 1em;
    }
    .education{
        font-size: 1em;
    }
    .paragraph-header{
        font-size: 1.35em;
    }
    .section-header{
        font-size: 1.15em;
        margin-left: 25px;
    }

}
@media only screen and (max-width: 450px) {
    .contact-item-title{
        font-size: 1.2rem;
        left: 1vw;
        width: 100%;
        top: 45vw;
   }
  .contact-item1{
    font-size: 3.5rem;
    left: 5vw;
    top: 47vw;
  }
  .contact-item2{
    font-size: 3.5rem;
    left: 35vw;
    top: 47vw;
    }
.contact-item3{
    font-size: 3.5rem;
    left: 63vw;
    top: 47vw;
}
.mainContainer{
    margin-top: 200px;
}
  
}


