:root{
    --gradient: linear-gradient(45deg, #bd8ca9 0%, #7930ee 100%);
    --light-gradient: linear-gradient(45deg, rgb(114, 110, 110) 0%, #8d999e 100%);
    --long: 0.7s;
    --short: 0.2s;
}
p{
    font-family: 'Abel', sans-serif;

}
.card{
    width: 300px;
    height: 520px;
    background-color: #ffffff;
    border-radius: 10px;
    border-style: solid;
    border-width: 0.5px;
    border-color: rgba(153, 153, 153, 0.6);
    display: grid;
    grid-template-rows: auto 292px;
    overflow: hidden;
    transition: 0.5s ease;
    cursor: pointer;
    position: relative;
 
}
.card:hover{
    transform: scale(1.05);
    box-shadow: 5px 5px 15px rgba(0,0,0,0.6);
}
.image-box{
    width: 300px;
    position: relative;
}
.image-profile{
    position: absolute;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
}
.bottom{
    display: grid;
    justify-content: center;
    align-content: center;
    text-align: center;
    width: 300px;
    position: relative;
    background: var(--light-gradient);
}
.title{
    font-size: 26px;
    font-weight: bold;
    font-family: 'Abel', sans-serif;
    color: #fff;
    
}
.description{
    font-size: 1em;
    padding: 10px;
    color: #fff;
    font-family: 'Abel', sans-serif;

}
.btn{
    position: absolute;
    width: 110px;
    height: 38px;
    background: rgb(79, 167, 71);
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 25px;
    color: #fff;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    z-index: 2;
    transition: var(--long);
}
.btn-close{
    padding-top: 5px;
    position: absolute;
    width: 110px;
    height: 38px;
    background: rgb(79, 167, 71);
    left: 50%;
    transform: translate(-50%, 220px);
    border-radius: 25px;
    color: #fff;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    z-index: 2;
    transition: var(--long);

}
.social{
    padding: 20px;
    position: absolute;
    background-color:transparent;
    top: -100%;
    left: 0;
    height: 100%;
    width: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 0;
    
}
.social p {
    margin-top: 50px;
    border: 1px solid #fff;
    height: fit-content;
    padding: 0 10px;
    border-radius: 20px;
}
.social-links{
    margin-top: 20px;
    width: 100%;
    padding: 10px 30px;
    display: flex; 
    justify-content: center;
    text-align: center;
}
.social-links img {
    width: 45px;
}
.social-links a {
    transition: var(--short);
}
a:hover {
    transform: translateY(-5px);
}

.card.active .social{
    top: 0;
    background-color: rgba(0,0,0,0.8);
}